import "./App.scss";
import Home from "./pages/Home";
import ChatBot from "./pages/ChatBot";
import LanguageAssistant from "./pages/LanguageAssistant";
import { Routes, Route, BrowserRouter } from "react-router-dom";
// import ErrorPage from "./components/ErrorPage";

function App() {
  return (
    <BrowserRouter >
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />}>
          <Route path="chatbot" element={<ChatBot />} />
          <Route path="language_assistant" element={<LanguageAssistant />} />
        </Route>
      </Routes>
    </div>
    </BrowserRouter>
  );
}

export default App;
